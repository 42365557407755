.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}
#layout-canvas .flash-message {
  display: none;
}
.flash-message {
  position: fixed;
  width: 500px;
  left: 50%;
  top: 13px;
  margin-left: -250px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 30px 10px 15px;
  z-index: 10300;
  word-wrap: break-word;
  text-shadow: 0 -1px 0px rgba(0, 0, 0, 0.15);
  text-align: center;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.flash-message.fade {
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.5s, width 0s;
  transition: all 0.5s, width 0s;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
}
.flash-message.fade.in {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.flash-message.success {
  background: #8da85e;
}
.flash-message.error {
  background: #cc3300;
}
.flash-message.warning {
  background: #f0ad4e;
}
.flash-message.info {
  background: #5fb6f5;
}
.flash-message button {
  float: none;
  position: absolute;
  right: 10px;
  top: 8px;
  color: white;
  outline: none;
}
.flash-message button:hover {
  color: white;
}
.flash-message.static {
  position: static !important;
  width: auto !important;
  display: block !important;
  margin-left: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
@media (max-width: 768px) {
  .flash-message {
    left: 10px;
    right: 10px;
    top: 10px;
    margin-left: 0;
    width: auto;
  }
}
